<template>
  <div class="wrap">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in list_data"
        :key="index"
        :label="item.title"
        :prop="item.value"
      >
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >编辑</el-button
          >
          <el-button class="btn" size="mini" @click="gdelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <br />
    <br />
    <h2>编辑套餐</h2>
    <el-form ref="form" :model="form" label-width="100px">
      <div class="form_box">
        <div>
          <el-form-item label="套餐名称">
            <el-input
              v-model="form.group_name"
              placeholder="套餐名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="大简历数量">
            <el-input
              v-model="form.max_num"
              placeholder="大简历数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="小简历数量">
            <el-input
              v-model="form.min_num"
              placeholder="小简历数量"
            ></el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="form.remarks" placeholder="备注"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <el-button class="btn" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  add_user_group,
  get_user_group,
  delete_user_group,
} from "@/api/resume";

import Tablebox from "./tabulate";

export default {
  components: { Tablebox },

  data() {
    return {
      tableData: [],
      list_data: [
        { title: "套餐名称", value: "group_name" },
        { title: "大简历", value: "max_num" },
        { title: "小简历", value: "min_num" },
        { title: "备注", value: "remarks" },
      ],

      form: {
        g_id: "",
        group_name: "",
        max_num: "",
        min_num: "",
        remarks: "",
      },
    };
  },
  methods: {
    // 删除套餐
    gdelete(e) {
      console.log("123456sssss");

      this.form.g_id = e.id;

      //删除
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delete_user_group({
            g_id: this.form.g_id,
          }).then((res) => {
            if (!res.code) {
              this.$message.success(res.msg);
              setTimeout(() => {
                this.get_list();
              }, 300);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //修改套餐
    redact(e) {
      console.log("123456sssss");

      this.form.g_id = e.id;
      this.form = {
        g_id: e.id,
        group_name: e.group_name,
        max_num: e.max_num,
        min_num: e.min_num,
        remarks: e.remarks,
      };
    },

    //编辑套餐
    onSubmit(e) {
      let insert_data = this.form;
      console.log(insert_data);

      add_user_group(insert_data).then((res) => {
        if (!res.code) {
          this.form = {
            g_id: "",
          };
          this.$message.success(res.msg);
          setTimeout(() => {
            this.get_list();
          }, 300);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_list() {
      get_user_group({}).then((res) => {
        if (res.code === 0) {
          console.log(res.data);

          this.tableData = res.data;
        }
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log("查询所有地区");
    this.get_list();
  },
};
</script>

<style scoped  lang='scss'>
.wrap {
  padding-top: 54px;
}
</style>
